
import { useState, useEffect } from "react";

import './Img.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetDataOpt, GetCustomization, RegisterCustomization } from "interface/InterfaceData";
import { SetModalData, SetModalState, RegisterModalData } from "interface/InterfacePopUp";
import { SvgImg } from "components/SvgFile";

export default function Img(props){

    const [ modalData, setModaldata ]     = useState([]);

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ name, setName ]           = useState(GetDataOpt(pageState.idMenu, pageState.idSubMenu, pageState.idSubMenu_sub, 'name'));
    const [ file, setFile ]           = useState(GetDataOpt(pageState.idMenu, pageState.idSubMenu, pageState.idSubMenu_sub, 'file'));
    const [ stateFile, setStateFile ] = useState([]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
        
        RegisterCustomization('menu', setListMenu);
        
        RegisterModalData('Crop', setModaldata);
    }, []); 

    useEffect(()=>{
    }, [modalData]);

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialName();
            initialFile()
        }
    }, [pageState]);  

    function initialName(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setName(data_menu.name);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setName(data_submenu.name);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setName(data_submenusub.name);
        }
    }
    function initialFile(){        
        if(pageState.idSubMenu_sub !=0){
            const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
            const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
            const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);            
            switch(props.type){
                case "menu":
                    const data_menu = dataMenu.contents.find(item => item.id == props.id);
                    //return data_menu.file;
                    setFile(data_menu.file);
                    
                case "submenu":
                    const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                    //return data_submenu.file;
                    setFile(data_submenu.file);
                    
                case "submenusub":
                    const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                    //return data_submenusub.file;
                    setFile(data_submenusub.file);
            }
        }
    }

    function handleName(value){
        setName(value); 
        props.RegisterData('img_name', { "id" : props.id, "name" : value, "index" : props.index }, props.index);
    }

    function handleFile(value){
        setStateFile({ "id" : props.id, "file" : URL.createObjectURL(value) });
        SetModalData('Crop', { "id" : props.id, "index" : props.index, "img" : value, "showImg" : URL.createObjectURL(value), "func" : props.RegisterData });
        SetModalState('Crop', true);
    }

    return(
        <div className="Img">
            <div className="div_input">
                <div>
                    Nome
                </div>
                <div className="space_input">
                    <input type="text" maxLength="60" value={ name } onChange={ (e)=>{ handleName(e.target.value) } } />
                </div>                
                <label> 
                    <div className={ stateFile.id == props.id ? "img_search add_file" : "img_search" }>
                        <div className="input_file"> 
                            {
                               stateFile.id == props.id ? "foto carregada" : "Adicionar imagem"
                            }
                        </div>     
                        <input type="file" onChange={ (e)=>{ handleFile(e.target.files[0]); } } accept="image/png, image/jpg, image/jpeg"/>        
                    </div>
                </label>
                {
                    file !="" ? 
                    <div onClick={ ()=>{ SetModalData("ShowFile", { "type" : "showImg", "file" : file }); SetModalState("ShowFile", true); } }>
                        <SvgImg color="#F00000" className="svg_opt_img" />
                    </div> : null  
                }
            </div>
        </div>
    )
}