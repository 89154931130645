
import React, { useRef, useState, useEffect } from "react";

import './ListData.css';

import { SvgAddData, SvgClose, SvgDelete, SvgSetaDown, SvgSetaUp } from "components/SvgFile";
import { GetListContents, GetCustomization, RegisterCustomization } from "interface/InterfaceData";
import { SetModalData, SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";
import { AltOrderContents, EditDataMenu } from "services/RegisterData";

export default function ListData(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ nameSubMenu, setNameSubMenu ]       = useState('');
    const [ nameSubMenuSub, setNameSubMenuSub ] = useState('');
    const [ contents, setContents ]             = useState([]);
    const [ countData, setCountData ]           = useState(0);

    const [ listContents, setListContents ] = useState(GetCustomization('menu'));

    const [ showContents, setShowContents ] = useState([]);

    useEffect(()=>{
        RegisterModalData('EditMenu', setModaldata);
        RegisterModalObserver('EditMenu', setShowPopUp);

        RegisterCustomization('menu', setListContents);
        RegisterCustomization('menu', setShowContents);
    }, []);

    useEffect(()=>{
        if(modalData.idMenu){
            ListTypes();
        }
    }, [modalData]);

    function ListTypes(){
        const data = GetListContents(modalData);
        setCountData(data.length);
        setShowContents(data)
    }

    function NewContents(){
        setContents([...contents, { "type" : "", "order" : '', "id" : 0 }])
    }

    function handleType(value, index){        
        const typeContents       = [...contents];
        typeContents[index].type = value;
        setContents(typeContents);
    }

    function save_data(){
        EditDataMenu(modalData.idSubMenu, modalData.idSubMenuSub, nameSubMenu, nameSubMenuSub, contents, ()=>{}, error());
        SetModalState('EditMenu', false);
    }

    function error(){        
    }

    function AltOrder(type, order, id, id_given){
        AltOrderContents(type, order, id, id_given, ()=>{}, error());
        SetModalState('EditMenu', false);
    }

    function ClosePopUp(){        
        SetModalState('EditMenu', false);
    }

    return (
        (showPopUp ?
            <>            
                <div className="PopUpEditMenu">
                    <div className="all">
                        <div className="div_data type_div">    
                            <div className="popup_title">
                                Editar informações do menu
                            </div>                        
                            <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>                     
                                <SvgClose color="#F00000" className="svg_opt" />
                            </div>
                        </div>   
                        <div className="div_data" style={ { paddingTop: 0 } }>                         
                            <div className="content">
                                <div>
                                    <div className="list_type_contents">
                                        <table width="100%">
                                            <thead>
                                                <tr>
                                                    <td width="30">#</td>
                                                    <td>Tipo</td>
                                                    <td width="90"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    showContents.map((key, index)=>{
                                                        return(
                                                            <tr key={ index }>
                                                                <td>{ index + 1 }</td>
                                                                <td>{ key.type }</td>
                                                                <td align="right">
                                                                    <div className="list_type_contents">
                                                                        <div className="order_alt">
                                                                            {
                                                                                index > 0 ? 
                                                                                <div className="order_alt" onClick={ ()=>{ AltOrder('up', key.order, key.id, key.id_given); } }>
                                                                                    <SvgSetaUp color="#2d3c53" className="svg_opt"/>
                                                                                </div> : null
                                                                            }
                                                                        </div>
                                                                        <div className="order_alt">
                                                                            {
                                                                                countData == index + 1 ? null :
                                                                                <div className="order_alt" onClick={ ()=>{ AltOrder('down', key.order, key.id, key.id_given); } }>
                                                                                    <SvgSetaDown color="#2d3c53" className="svg_opt"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div onClick={ ()=>{ SetModalData("Confirmation", { "type" : "delete_contents", "id" : key.id }); SetModalState("Confirmation", true); } }>
                                                                            <SvgDelete color="#F00000" className="svg_opt" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ) 
                                                    }) 
                                                }
                                                {
                                                    contents.sort().map((key, index)=>{
                                                        return(
                                                            <tr key={ index }>
                                                                <td>{ index + 1 }</td>
                                                                <td>
                                                                    <select onChange={ (e)=>{ handleType(e.target.value, index) } } defaultValue="">
                                                                        <option value="">#</option>
                                                                        <option value="title">Título</option>
                                                                        <option value="text">Texto</option>
                                                                        <option value="video">Vídeo</option>
                                                                        <option value="link">Link</option>
                                                                        <option value="contact">Contato</option>
                                                                        <option value="img">Imagem</option>
                                                                        <option value="pdf">Pdf</option>
                                                                        <option value="flickr">Flickr</option>
                                                                    </select>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        )
                                                    }) 
                                                }
                                                <tr>
                                                    <td colSpan="3">
                                                        <div onClick={ ()=>{ NewContents() }} align="right">
                                                            <SvgAddData color="#2d3c53" className="svg_opt" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div className="button_save" onClick={ ()=>{ save_data() } }>
                                        Salvar
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </> 
        : <React.Fragment></React.Fragment>)
    );
}