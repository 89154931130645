const ModalState = {
    ShowData     : true,
    Confirmation : true,
    SavedData    : true,
    ShowFile     : true,
    EditMenu     : true,
    NewMenu      : true,
    Crop         : true
};

const ModalData = {
    ShowData     : {},
    Confirmation : {},
    SavedData    : {},
    ShowFile     : {},
    EditMenu     : {},
    NewMenu      : {},
    Crop         : {}
}

const NotifyModalObserver = {
    ShowData     : [],
    Confirmation : [],
    SavedData    : [],
    ShowFile     : [],
    EditMenu     : [],
    NewMenu      : [],
    Crop         : []
}

const NotifyModalData = {
    ShowData     : [],
    Confirmation : [],
    SavedData    : [],
    ShowFile     : [],
    EditMenu     : [],
    NewMenu      : [],
    Crop         : []
}

export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}

export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}

export function GetModalState(key, value){
    return ModalData[key][value];
}

export function GetModalData(){
    return ModalData['SavedData'];
}

export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}

export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}