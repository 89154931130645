
export const dataPage = {
    contact  : [],
    img_name : [],
    img      : [],
    video    : [],
    link     : [],
    flickr   : [],
    pdf      : [],
    text     : [],
    title    : []
};

let Customization = {
    "customization" : {
        "menu_background"  : "#2d3c53",
        "menu_text"        : "#FFFFFF",
        "menu_active"      : "#FFC400",
        "topic_background" : "#c5d2e8",
        "topic_text"       : "#2d3c53"
    },
    "menu" : [],
    "update" : false
};

let NotifyCustomization = {
    "customization" : [],
    "menu"          : [],
    "update"        : []
}

export function SetListData(value) {
    Customization = value;  
    NotifyCustomization["customization"].forEach(element => {
        element(value.customization);
    });
    NotifyCustomization["menu"].forEach(element => {
        element(value.menu);
    });
    NotifyCustomization["update"].forEach(element => {
        element(value.update);
    });
}

export function SetCustomization(key, value){
    Customization[key] = value;
    NotifyCustomization[key].forEach(element => {
        element(value);
    });
}
export function GetCustomization(key){
    return Customization[key];   
}
export function GetDataOpt(idMenu, idSubMenu, idSubMenu_sub, type){
    if(idMenu !=0){
        if(idSubMenu !=0){
            const dataMenu       = Customization['menu'].find(item => item.id == idMenu);
            const dataSubMenu    = dataMenu.submenu.find(item => item.id == idSubMenu);
            const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == idSubMenu_sub); 
            return dataSubMenuSub[type]; 
        }
    }  
}
export function GetListContents(showId){
    if(showId['idSubMenuSub'] > 0){
        const dataMenu       = Customization['menu'].find(item => item.id == showId['idMenu']);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == showId['idSubMenu']);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == showId['idSubMenuSub']); 
        return dataSubMenuSub.contents; 
    }  
}

export function RegisterCustomization(key, value){
    if(!NotifyCustomization[key]){
        NotifyCustomization[key] = []; 
    }
    NotifyCustomization[key].push(value);
}