
import { useState, useEffect } from "react";

import './Contact.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function Contact(props){

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ name, setName ]         = useState('');
    const [ phone, setPhone ]       = useState('');
    const [ email, setEmail ]       = useState('');
    const [ link, setLink ]         = useState('');
    const [ endereco, setEndereco ] = useState('');
    const [ time, setTime ]         = useState('');

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
        
        RegisterCustomization('menu', setListMenu);
    }, []);  

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialName();
            initialPhone();
            initialEmail();
            initialLink();
            initialEndereco();
            initialTime();
        }
    }, [pageState]);  

    function initialName(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setName(data_menu.name);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setName(data_submenu.name);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setName(data_submenusub.name);
        }
    }
    function initialPhone(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setPhone(data_menu.phone);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setPhone(data_submenu.phone);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setPhone(data_submenusub.phone);
        }
    }
    function initialEmail(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setEmail(data_menu.email);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setEmail(data_submenu.email);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setEmail(data_submenusub.email);
        }
    }
    function initialLink(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setLink(data_menu.link);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setLink(data_submenu.link);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setLink(data_submenusub.link);
        }
    }
    function initialEndereco(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setEndereco(data_menu.endereco);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setEndereco(data_submenu.endereco);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setEndereco(data_submenusub.endereco);
        }
    }
    function initialTime(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setTime(data_menu.time);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setTime(data_submenu.time);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setTime(data_submenusub.time);
        }
    }

    function handleName(value){
        setName(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : value, "phone" : phone, "time" : time, "email" : email, "link" : link, "endereco" : endereco, "index" : props.index }, props.index);
    }

    function handlePhone(value){
        setPhone(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : name, "phone" : value, "time" : time, "email" : email, "link" : link, "endereco" : endereco, "index" : props.index }, props.index);
    }

    function handleEmail(value){
        setEmail(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : name, "phone" : phone, "time" : time, "email" : value, "link" : link, "endereco" : endereco, "index" : props.index }, props.index);
    }

    function handleLink(value){
        setLink(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : name, "phone" : phone, "time" : time, "email" : email, "link" : value, "endereco" : endereco, "index" : props.index }, props.index);
    }

    function handleEndereco(value){
        setEndereco(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : name, "phone" : phone, "time" : time, "email" : email, "link" : link, "endereco" : value, "index" : props.index }, props.index);
    }

    function handleTime(value){
        setTime(value); 
        props.RegisterData('contact', { "id" : props.id, "name" : name, "phone" : phone, "time" : value, "email" : email, "link" : link, "endereco" : endereco, "index" : props.index }, props.index);
    }

    return(
        <div className="Contact">
            <div className="div_input div_contact">
                <div>
                    Nome
                </div>
                <div className="space_input">
                    <input type="text" maxLength="60" value={ name } onChange={ (e)=>{ handleName(e.target.value) } } />
                </div>
                <div>
                    Contato
                </div>
                <div className="space_input">
                    <input type="text" maxLength="22" value={ phone } onChange={ (e)=>{ handlePhone(e.target.value) } } />
                </div>
            </div>
            <div className="div_input div_contact">
                <div>
                    E-mail
                </div>
                <div className="space_input">
                    <input type="text" maxLength="60" value={ email } onChange={ (e)=>{ handleEmail(e.target.value) } } />
                </div>
                <div>
                    Hora
                </div>
                <div className="space_input">
                    <input type="text" maxLength="120" value={ time } onChange={ (e)=>{ handleTime(e.target.value) } } />
                </div>
            </div>
            <div className="div_input div_contact">
                <div>
                    Link
                </div>
                <div className="space_input">
                    <input type="text" value={ link } onChange={ (e)=>{ handleLink(e.target.value) } } />
                </div>
            </div>
            <div className="div_input div_contact" style={ { marginBottom: 0 } }>
                <div>
                    Endereço
                </div>
                <div className="space_input">
                    <input type="text" value={ endereco } onChange={ (e)=>{ handleEndereco(e.target.value) } } />
                </div>
            </div>
        </div>
    )
}