
import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { RegisterAltData } from "services/RegisterData";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";

export default function Confirmation(){

    const [ modalData, setModaldata ]       = useState({});
    const [ showPopUp, setShowPopUp ]       = useState(false);

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    function TypeAlt(type){
        RegisterAltData(type, modalData.id);
        SetModalState('Confirmation', false);
    }

    function ClosePopUp(){        
        SetModalState('Confirmation', false);
    }

    return (
        (showPopUp ?
            <>            
                <div className="PopUpConfirmation">
                    <div className="all">
                        <div className="div_data">    
                            <div className="popup_title">
                                {
                                    modalData.type == 'updateJson' ? "Atualizar plataforma" : "Deletar menu"
                                }
                            </div>  
                        </div>   
                        <div className="div_data" style={ { paddingTop: 0 } }>                         
                            <div className="content">                            
                                <div onClick={ ()=>{ TypeAlt(modalData.type); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </> 
        : <React.Fragment></React.Fragment>)
    );
}