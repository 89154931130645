
import { useState, useEffect } from "react";

import './ContentList.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";
import { SetModalData, SetModalState, RegisterModalData, GetModalData } from "interface/InterfacePopUp";

import Title from "components/Input/Title";
import Text from "components/Input/Text";
import Contact from "components/Input/Contact";
import Pdf from "components/Input/Pdf";
import Img from "components/Input/Img";
import Video from "components/Input/Video";
import Link from "components/Input/Link";

import { EditMenu, Register } from "services/RegisterData";
import { SvgDelete, SvgEdit } from "components/SvgFile";

import { dataPage } from "interface/InterfaceData";
import Flickr from "components/Input/Flickr";

export default function ContentList(){

    const [ modalData, setModaldata ]         = useState({});
    
    const [ pageClicked, setPageClicked ]   = useState('');
    const [ pageState, setPageState ]       = useState(GetListPag('currentRegister'));
    const [ listContents, setListContents ] = useState(GetCustomization('menu'));
    const [ stateSaved, setStateSaved ]     = useState(GetCustomization('update'));
    const [ nameMenu, setNameMenu ]         = useState('');
    const [ stateMenu, setStateMenu ]       = useState(false);

    useEffect(()=>{
        RegisterListPag('currentRegister', setPageState);
        RegisterCustomization('menu', setListContents);
        RegisterCustomization('update', setStateSaved);
        
        RegisterModalData('Crop', setModaldata);
        
    }, []);

    useEffect(()=>{
        if(pageState.idMenu !=0){
            const dataMenu = listContents.find(item => item.id == pageState.idMenu);
            if(pageState.idSubMenu !=0){
                const dataSubMenu = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
                if(pageState.idSubMenu_sub == 0){
                    setPageClicked(dataSubMenu.name);
                }else{
                    const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);
                    setPageClicked(dataSubMenuSub.name);
                }
            }else{
                setPageClicked(dataMenu.name);
            }
        }
    }, [pageState]);

    function PopUpEditMenu(idMenu, idSubMenu, idSubMenuSub){
        SetModalData("EditMenu", { "idMenu" : idMenu, "idSubMenu" : idSubMenu, "idSubMenuSub" : idSubMenuSub }); 
        SetModalState("EditMenu", true);
    }

    let listDataContents = [];
    
    function RegisterData(type, input_value, input_index){
        switch (type) {
            case 'contact':  
                dataPage.contact[input_index] = input_value;
                break;
                
            case 'img_name': 
                dataPage.img_name[input_index] = input_value; 
                break;
                
            case 'img': 
                dataPage.img[input_index] = input_value; 
                break;
                
            case 'video':  
                dataPage.video[input_index] = input_value;
                break;
                
            case 'link':  
                dataPage.link[input_index] = input_value;
                break;
                
            case 'flickr':  
                dataPage.flickr[input_index] = input_value;
                break;

            case 'pdf':  
                dataPage.pdf[input_index] = input_value;
                break;
                
            case 'text':  
                dataPage.text[input_index] = input_value;
                break;
                
            case 'title': 
                dataPage.title[input_index] = input_value; 
                break;
        }
    }

    function ListData(){
        if(pageState.idMenu !=0){
            const dataMenu = listContents.find(item => item.id == pageState.idMenu);
            if(pageState.idSubMenu !=0){
                const dataSubMenu = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);

                if(pageState.idSubMenu_sub != 0){
                    const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);
                    dataSubMenuSub.contents.map((value, index_)=>{
                        if(value.type == 'title'){
                            listDataContents.push(<Title type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'text'){
                            listDataContents.push(<Text type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'video'){
                            listDataContents.push(<Video type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'link'){
                            listDataContents.push(<Link type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'flickr'){
                            listDataContents.push(<Flickr type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'contact'){
                            listDataContents.push(<Contact type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'pdf'){
                            listDataContents.push(<Pdf type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }

                        if(value.type == 'img'){
                            listDataContents.push(<Img type="submenusub" RegisterData={ RegisterData } index={ index_ } id={ value.id } />)
                        }
                    });
                }
            }else {
            }            
        }
        return(
            <div className="contents_list">
                <div className="data_list">
                    {
                        Object.keys(listDataContents).map((key, index)=>{
                            return(
                                <div key={ index } >
                                    { listDataContents[key] }
                                </div>
                            )
                        })
                    }
                    {
                        Object.keys(listDataContents).length > 0 ? 
                        <div className="content div_data">
                            <div className="button_save" onClick={ ()=>{ SaveData() } }>
                                Salvar
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        )
    }

    function handleNameMenu(value, id){   
        const copyList = [...listContents];  
        const dataMenu = copyList.find(item => item.id == id);   
        dataMenu.name  = value;
        setListContents(copyList);
    }

    function RegisterNameMenu(id, value){
        EditMenu(id, value, ()=>{}, ()=>{});
    }
    function ListAllMenu(){
        return(
            <div className="contents_list">
                <div className="data_list">
                    <div className="opt_list_menu">
                        <div className="title_page">Lista dos menus na lateral</div>
                        <div className="new_menu">
                            {/* <div className="button_save">Adicionar novo menu</div> */}
                        </div>
                    </div>
                    {
                    listContents.map((menu, index)=>{
                        if(menu.name !='Customização'){
                            return(
                                <div key={ index } className="data_list list_menu">
                                    <div>
                                        <div className="title_menu">
                                            <div className="space_menu">
                                                { menu.name }
                                            </div>
                                        </div>
                                        <div>
                                            <table width="100%">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="2">Submenu</td>
                                                        <td>Conteúdo</td>
                                                        <td width="50">#</td>
                                                    </tr>
                                                </thead>
                                                {
                                                    menu.submenu.map((showSubMenu, index_1)=>{
                                                        return(
                                                            <tbody key={ index_1 }>
                                                                <tr>
                                                                    <td width="120" rowSpan={ showSubMenu.submenu.length + 1 }>{ showSubMenu.name }</td>
                                                                </tr>
                                                                {
                                                                    showSubMenu.submenu.map((showSubMenuSub, index_2)=>{
                                                                        return(
                                                                            <tr key={ index_2 }>
                                                                                <td width="120">{ showSubMenuSub.name }</td>
                                                                                <td>
                                                                                    <div className="list_type_contents">
                                                                                        {
                                                                                            showSubMenuSub.contents.map((typeDataPage, index_3)=>{
                                                                                                return(
                                                                                                    <div className="type_contents" key={ index_3 } onClick={ ()=>{ SetModalData("Confirmation", { "type" : "delete_contents", "id" : typeDataPage.id }); SetModalState("Confirmation", true); } }>
                                                                                                        { typeDataPage.type }
                                                                                                    </div>
                                                                                                )  
                                                                                            }) 
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>        
                                                                                    <span onClick={ ()=>{ PopUpEditMenu(menu.id, showSubMenu.id, showSubMenuSub.id) } }>
                                                                                        <SvgEdit color="#2d3c53" className="svg_opt" />
                                                                                    </span>
                                                                                    <span onClick={ ()=>{ SetModalData("Confirmation", { "type" : "delete_menu", "id" : showSubMenuSub.id }); SetModalState("Confirmation", true); } }>
                                                                                        <SvgDelete color="#F00000" className="svg_opt" />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        )
                                                    })  
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                </div>
            </div>
        )
    }

    function SaveData(){
        Register(dataPage.contact, dataPage.img_name, dataPage.img, dataPage.video, dataPage.link, dataPage.flickr, dataPage.pdf, dataPage.text, dataPage.title, ()=>{}, error());
        SetModalState('SavedData', true);
    }

    function error(){
    }
    
    return(
        <div className="ContentList">
            {
                pageClicked == 'Customização' ? ListAllMenu() : ListData()
            }
        </div>
    );
}