
import { useState, useEffect } from "react";

import './MenuLeft.css';

import Cookies from 'universal-cookie';

import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";
import { SvgCircle, SvgSetaRight } from "components/SvgFile";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

export default function MenuLeft(){
    const cookies = new Cookies(); 
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ typeColor, setTypeColor ]     = useState(GetCustomization('customization'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);

        RegisterCustomization('menu', setListMenu);
        RegisterCustomization('customization', setTypeColor);

        const rootComponent = document.querySelector(':root');
        rootComponent.style.setProperty('--menu_background', typeColor.menu_background);
        rootComponent.style.setProperty('--menu_text', typeColor.menu_text);
        rootComponent.style.setProperty('--menu_active', typeColor.menu_active);

    }, []);

    // id page click
    function IdPageClick(idMenu, idSubMenu, idSubMenuSub){
        SetListPag('currentRegister', {"idMenu" : idMenu, "idSubMenu" : idSubMenu, "idSubMenu_sub" : idSubMenuSub});  
    }

    // click page
    function clickPage(type, value, idPage){  
        const newData  = [...listMenu];        
        newData.map((key, index) => {
            key.state = false;
            if(type = 'menu'){
                if(key.id == idPage){
                    key.state = true;
                }   
            }
            key.submenu.map((key_1, index_1) => {
                key_1.state = false;
                if(type = 'submenu'){ 
                    if(key_1.id == idPage){
                        key_1.state = true;
                    }                
                }
                key_1.submenu.map((key_2, index_2) => {
                    key_2.state = false;
                    if(type = 'submenusub'){ 
                        if(key_2.id == idPage){
                            key_2.state = true;
                        }                
                    }                    
                });
            });
        });
        setListMenu(newData); 
        SetListPag('currentPage', value); 
    }

    function UpdateJson(){
        SetModalData("Confirmation", { "type" : "updateJson" });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="ListMenu">
            <div className="list_menu_left">
                {
                    listMenu.map((value, index) => {
                        return(
                            <div key={ index }>
                                { 
                                    value.submenu.length > 0 ? 
                                    <>
                                        <div className="menu_name" onClick={ ()=>{ clickPage('menu', value.name, value.id); IdPageClick(value.id, 0, 0) } }>
                                            <div>{ value.name }</div>
                                            <div>
                                                { 
                                                    value.id == pageState.idMenu || value.name == pageClicked ? 
                                                    <SvgSetaRight color="#FFFFFF" className="svg_opt icons_rotate" /> :
                                                    <SvgSetaRight color="#FFFFFF" className="svg_opt" />
                                                }
                                            </div>
                                        </div>
                                        <div className={ value.id == pageState.idMenu || value.name == pageClicked ? "list_sub_menu sub_menu_active" : "list_sub_menu" }>
                                            { 
                                                value.submenu.map((name_submenu, index_) => {
                                                    return(
                                                        <div key={ index_ }>
                                                            {
                                                                name_submenu.submenu.length > 0 ? 
                                                                <>
                                                                    <div className="menu_name" onClick={ ()=>{ clickPage('submenu', name_submenu.name, name_submenu.id);  IdPageClick(value.id, name_submenu.id, 0) } }>
                                                                        <div>{ name_submenu.name }</div>
                                                                        <div>
                                                                            { 
                                                                                name_submenu.id == pageState.idSubMenu || name_submenu.name == pageClicked ? 
                                                                                <SvgSetaRight color="#FFFFFF" className="svg_opt icons_rotate" /> :
                                                                                <SvgSetaRight color="#FFFFFF" className="svg_opt" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className={ name_submenu.id == pageState.idSubMenu || name_submenu.name == pageClicked ? "list_sub_menu sub_menu_active" : "list_sub_menu" }>
                                                                        {
                                                                            name_submenu.submenu.map((name_submenu_sub, index_sub)=>{
                                                                                return(
                                                                                    <div key={ index_sub } className={ name_submenu_sub.id == pageState.idSubMenuSub || name_submenu_sub.name == pageClicked ? "name_sub_menu active" : "name_sub_menu" } onClick={ ()=>{ clickPage('submenusub', name_submenu_sub.name, name_submenu_sub.id); IdPageClick(value.id, name_submenu.id, name_submenu_sub.id) } }>
                                                                                        { name_submenu_sub.name }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </> :
                                                                <div className={ name_submenu.id == pageState.idSubMenu || name_submenu.name == pageClicked ? "name_sub_menu active" : "name_sub_menu" } onClick={ ()=>{ clickPage(name_submenu.name, name_submenu.id); IdPageClick(value.id, name_submenu.id, 0) } }>
                                                                    { name_submenu.name }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                    : 
                                    <div className={ value.id == pageState.idMenu ? "menu_name active" : "menu_name" } onClick={ ()=>{ clickPage(value.name, value.id); IdPageClick(value.id, 0,  0) } }>
                                        <div>
                                            { value.name }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                <div className="menu_name" onClick={ ()=>{ UpdateJson(); } }>
                    <div>
                        Atualizar plataforma
                    </div>
                </div>
            </div>
        </div>
    )
}