
import React, { useState, useEffect } from "react";
import Iframe from 'react-iframe';

import './ShowFile.css';

import { SvgClose } from "components/SvgFile";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";

export default function ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    function ClosePopUp(){        
        SetModalState('ShowFile', false);
    }

    return (
        (showPopUp ?
            <>            
                <div className="PopUpFile">
                    <div className="all">
                        <div className="div_data type_div">    
                            <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>                     
                                <SvgClose color="#F00000" className="svg_opt" />
                            </div>
                        </div>   
                        <div className="div_data" style={ { paddingTop: 0 } }>                         
                            <div className="content">
                                {
                                    modalData.type == "showImg" ? 
                                    <img alt="img" src={ modalData.file } className="show_img" /> :
                                    <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative"/>
                                }
                            </div>
                        </div> 
                    </div>
                </div> 
            </> 
        : <React.Fragment></React.Fragment>)
    );
}