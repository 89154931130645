
import React, { useState, useEffect } from "react";

import './SavedData.css';

import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";

import { SvgClose } from "components/SvgFile";

export default function SavedData(){

    const [ modalData, setModaldata ]       = useState({});
    const [ showPopUp, setShowPopUp ]       = useState(false);
    const [ stateSaved, setStateSaved ]     = useState(GetCustomization('update'));

    useEffect(()=>{
        RegisterModalData('SavedData', setModaldata);
        RegisterModalObserver('SavedData', setShowPopUp);

        RegisterCustomization('update', setStateSaved);
    }, []);

    function ClosePopUp(){  
        SetModalState('SavedData', false); 
    }

    return (
        (showPopUp ?
            <>            
                <div className="PopUpSavedData">
                    <div className="all">
                        <div className="div_data">                          
                            {
                                stateSaved == true ?
                                <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>                     
                                    <SvgClose color="#F00000" className="svg_opt" />
                                </div> : null
                            }
                        </div>  
                        <div className="div_data" style={ { paddingTop: 0 } }>                         
                            <div className="content">
                                <div className="popup_title">
                                    {
                                        stateSaved == false ? "Aguarde os dados estão sendo salvos..." : "Dados atualizados com sucesso"
                                    }                                    
                                </div>  
                            </div>
                        </div>
                    </div>
                </div> 
            </> 
        : <React.Fragment></React.Fragment>)
    );
}