
import React, { useState, useEffect } from "react";

import './NewMenu.css';

import { AddNewMenu } from "services/RegisterData";
import { SvgAddData, SvgClose } from "components/SvgFile";
import { SetModalState, RegisterModalObserver } from "interface/InterfacePopUp";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function NewMenu(){

    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ nameMenu, setNameMenu ]         = useState([{ "type" : 0, "name" : "" }]);
    const [ contents, setContents ]         = useState([{ "name" : "" }]);
    const [ typeContents, setTypeContents ] = useState([{ "index" : "", "type" : "" }]);

    const [ typeNewData, setTypeNewData]    = useState(0);
    const [ listContents, setListContents ] = useState(GetCustomization('menu'));

    useEffect(()=>{
        RegisterModalObserver('ShowData', setShowPopUp);

        RegisterCustomization('menu', setListContents);
    }, []);

    function NewContents(){
        setContents([...contents, { "name" : "" }])
    }

    function NewTypeContents(index){
        setTypeContents([...typeContents, { "index" : index, "type" : "" }])
    }

    function handleMenu(type, value){
        setNameMenu([{ "type" : type, "name" : value }]);
    }

    function handleNameSubMenu(value, index){        
        const typeContents = [...contents];
        typeContents[index].name = value;
        setContents(typeContents);
    }

    function handleType(value, index){        
        const newData = [...typeContents];
        newData[index].type = value;
        setContents(newData);
    }

    function save_data(){
        AddNewMenu(nameMenu, contents, ()=>{}, error());
        SetModalState('ShowData', false);
    }

    function error(){        
    }

    function ClosePopUp(){        
        SetModalState('ShowData', false);
    }
    return (
        (showPopUp ?
            <>            
                <div className="PopUpNewMenu">
                    <div className="all">
                        <div className="div_data type_div">    
                            <div className="popup_title">
                                Adicionar novo menu
                            </div>                        
                            <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>                     
                                <SvgClose color="#F00000" className="svg_opt" />
                            </div>
                        </div>   
                        <div className="div_data" style={ { paddingTop: 0 } }>                         
                            <div className="content">
                                <div className="type_data">
                                    <div className={ typeNewData == 0 ? "button_save button_type_data button_active" : "button_save button_type_data" } onClick={ ()=>{ setTypeNewData(0) } }>
                                        Novo menu
                                    </div>
                                    <div className={ typeNewData == 1 ? "button_save button_type_data button_active" : "button_save button_type_data" } onClick={ ()=>{ setTypeNewData(1) } }>
                                        Submenu em menu existente
                                    </div>
                                </div>
                                {
                                    typeNewData == 1 ?
                                    <div>
                                        <div className="div_new_data">
                                            <div>Selecione o menu</div>
                                            <div className="div_input">
                                                <select onChange={ (e)=>{ handleMenu('addSubMenu', e.target.value) } } defaultValue="">
                                                    <option value="">#</option>
                                                    {
                                                        listContents.map((key, index)=>{
                                                            if(key.name != 'Customização'){
                                                                return(
                                                                    <option value={ key.id } key={ index }>{ key.name }</option>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </select>  
                                            </div> 
                                        </div>
                                    </div> :                                     
                                    <div>
                                        <div className="div_new_data">
                                            <div>Nome do menu</div>
                                            <div className="div_input">
                                                <input type="text" onChange={ (e)=>{ handleMenu('newMenu', e.target.value) } } />
                                            </div>
                                        </div>
                                    </div>
                                } 
                                <div>
                                    {
                                        contents.map((key, index)=>{
                                            return(
                                                <div className="new_submenu" key={ index }>  
                                                    <div className="div_new_data">
                                                        <div>Nome do submenu</div>
                                                        <div className="div_input">
                                                            <input type="text" onChange={ (e)=>{ handleNameSubMenu(e.target.value, index) } } />
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                        {
                                                            typeContents.map((key_, index_)=>{
                                                                if(key_.index == index){
                                                                    return(
                                                                        <div key={ index_ } className="div_new_data">
                                                                            <div>Tipo de conteúdo</div>
                                                                            <div className="div_input">
                                                                                <select onChange={ (e)=>{ handleType(e.target.value, index_) } } defaultValue="">
                                                                                    <option value="">#</option>
                                                                                    <option value="title">Título</option>
                                                                                    <option value="text">Texto</option>
                                                                                    <option value="linkVideo">Link / Vídeo</option>
                                                                                    <option value="contact">Contato</option>
                                                                                    <option value="img">Imagem</option>
                                                                                    <option value="pdf">Pdf</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        <div align="right">
                                                            <span onClick={ ()=>{ NewTypeContents(index) }} >
                                                                <SvgAddData color="#2d3c53" className="svg_opt" />
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            )
                                        })
                                    }        
                                    <div className="add_submenu">
                                        <div className="div_new_data" style={ { marginBottom: 16, marginTop: 0 } }>
                                            <div>Adicionar novo submenu</div>
                                            <div onClick={ ()=>{ NewContents() } } style={ { display: 'flex' } }>
                                                <SvgAddData color="#2d3c53" className="svg_opt" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="button_save" onClick={ ()=>{ save_data() } }>
                                        Salvar
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </> 
        : <React.Fragment></React.Fragment>)
    );
}