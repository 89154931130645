import Axios from 'axios';
import { SetListData } from 'interface/InterfaceData';

export function Register(contact, img_name, img, video, link, flickr, pdf, text, title, callback_success, callback_error){
    const data = new FormData();

    contact.forEach(element => {
        data.append('contact_id[]', element.id);
        data.append('contact_name[]', element.name);
        data.append('contact_phone[]', element.phone);
        data.append('contact_email[]', element.email);
        data.append('contact_link[]', element.link);
        data.append('contact_index[]', element.index);
        data.append('contact_endereco[]', element.endereco);
        data.append('contact_time[]', element.time);
    });

    img_name.forEach(element => {
        data.append('img_name_id[]', element.id);
        data.append('img_name[]', element.name);
        data.append('img_name_index[]', element.index);
    });
    
    img.forEach(element => {
        data.append("img_id[]", element.id)
        data.append("img_index[]", element.index);
        data.append("div_width[]", element.div_width);
        data.append("div_height[]", element.div_height);
        data.append("img[]", element.img);
        data.append("positionX[]", element.positionX);
        data.append("positionY[]", element.positionY);
        data.append("width[]", element.width);
        data.append("height[]", element.height);
    });

    video.forEach(element => {
        data.append('video_id[]', element.id);
        data.append('video_typeLink[]', element.typeLink);
        data.append('video_title[]', element.title);
        data.append('video_link[]', element.link);
        data.append('video_index[]', element.index);
    });

    link.forEach(element => {
        data.append('link_id[]', element.id);
        data.append('link_title[]', element.title);
        data.append('link_link[]', element.link);
        data.append('link_index[]', element.index);
    });

    flickr.forEach(element => {
        data.append('flickr_id[]', element.id);
        data.append('flickr_title[]', element.title);
        data.append('flickr_link[]', element.link);
        data.append('flickr_index[]', element.index);
    });

    let count_pdf = 0;
    pdf.forEach(element => {
        data.append('pdf_id[]', element.id);
        data.append('pdf_name[]', element.name);
        data.append('pdf_file_' + count_pdf, element.file);
        data.append('pdf_index[]', element.index);
        count_pdf++;
    });

    text.forEach(element => {
        data.append('text_id[]', element.id);
        data.append('text_text[]', element.text);
        data.append('text_index[]', element.index);
    });

    title.forEach(element => {
        data.append('title_id[]', element.id);
        data.append('title_title[]', element.title);
        data.append('title_subtitle[]', element.subtitle);
        data.append('title_index[]', element.index);
    });
    
    Axios({
        url     : '/php/register.php?acao=add_data',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}

export function RegisterAltData(type, id){   
    const data = new FormData(); 
    data.append('type', type);
    data.append('id_data', id);
    Axios({
        url     : '/php/register.php?acao=RegisterAltData',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}

export function EditDataMenu(idSubMenu, idSubMenuSub, nameSubMenu, nameSubMenuSub, contents, callback_success, callback_error){   
    const data = new FormData(); 
    data.append('idSubMenu', idSubMenu);
    data.append('idSubMenuSub', idSubMenuSub);
    data.append('nameSubMenu', nameSubMenu);
    data.append('nameSubMenuSub', nameSubMenuSub);

    contents.forEach(element => {
        if(element.id == 0){
            data.append('id[]', element.id);
            data.append('type[]', element.type);
        }
    });

    Axios({
        url     : '/php/register.php?acao=edit_submenu',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}

export function AddNewMenu(nameMenu, contents, callback_success, callback_error){   
    const data = new FormData(); 
    nameMenu.forEach(element => {
        data.append('type', element.type);
        data.append('id_data', element.name);
    });
    contents.forEach(element => {
        data.append('name[]', element.name);
    });

    Axios({
        url     : '/php/register.php?acao=menu_add',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}

export function EditMenu(id, name, callback_success, callback_error){   
    const data = new FormData(); 
    data.append('id', id);
    data.append('name', name);

    Axios({
        url     : '/php/register.php?acao=edit_menu',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}

export function AltOrderContents(type, order, id, id_given, callback_success, callback_error){   
    const data = new FormData(); 
    data.append('type', type);
    data.append('order', order);
    data.append('id', id);
    data.append('id_given', id_given);

    Axios({
        url     : '/php/register.php?acao=alt_order',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetListData(response.data);
    })
}