
import { useState, useEffect } from "react";

import './FixedTopic.css';

import Logo from 'assets/logo.png';

import { SvgSetaRight } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function FixedTopic(){    

    const [ pageClicked, setPageClicked ]   = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]       = useState(GetListPag('currentRegister'));
    const [ typeColor, setTypeColor ]       = useState(GetCustomization('customization'));
    const [ listContents, setListContents ] = useState(GetCustomization('menu'));

    const [ nameClicked, setNameClicked ] = useState([]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
        RegisterCustomization('customization', setTypeColor);

        RegisterCustomization('menu', setListContents);

        const rootComponent = document.querySelector(':root');
        rootComponent.style.setProperty('--topic_background', typeColor.topic_background);
        rootComponent.style.setProperty('--menu_background', typeColor.menu_background);
        rootComponent.style.setProperty('--topic_text', typeColor.topic_text);
    }, []);

    useEffect(()=>{
        if(pageState.idMenu !=0){
            const dataMenu = listContents.find(item => item.id == pageState.idMenu);
            if(pageState.idSubMenu !=0){
                const dataSubMenu = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
                if(pageState.idSubMenu_sub == 0){
                    setNameClicked([dataMenu.name, dataSubMenu.name]);
                }else{
                    const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);
                    setNameClicked([dataMenu.name, dataSubMenu.name, dataSubMenuSub.name]);
                }
            }else{
                setNameClicked([dataMenu.name]);
            }
        }
    }, [pageState]);
    
    return(        
        <div className="ListTopic">
            <div className="logotipo">
                <img alt="logotipo" src={ Logo } className="logo" />
            </div>
            <div className="show_name_menu">
                <div>Dashboard</div>
                { 
                    pageClicked == 'index' ? null :
                    <>
                        {
                            nameClicked.map((key, index)=>{
                                return(
                                    <div key={ index } style={ { display: "flex" } }>                             
                                        <SvgSetaRight color="#2D3C53" className="svg_opt" />
                                        { key }
                                    </div>  
                                )
                            })
                        }
                    </> 
                }
            </div>
        </div>
    )
}