
import { useState, useEffect } from "react";

import './Pdf.css';


import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataOpt, GetCustomization, RegisterCustomization } from "interface/InterfaceData";

import { SvgPdf } from "components/SvgFile";

export default function Pdf(props){

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ name, setName ]           = useState(GetDataOpt(pageState.idMenu, pageState.idSubMenu, pageState.idSubMenu_sub, 'name'));
    const [ file, setFile ]           = useState(GetDataOpt(pageState.idMenu, pageState.idSubMenu, pageState.idSubMenu_sub, 'file'));
    const [ stateFile, setStateFile ] = useState([]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
        
        RegisterCustomization('menu', setListMenu);
    }, []);    

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialName();
            initialFile();
        }
    }, [pageState]); 
    
    function initialName(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setName(data_menu.name);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setName(data_submenu.name);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setName(data_submenusub.name);
        }
    }
    function initialFile(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setFile(data_menu.file);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setFile(data_submenu.file);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setFile(data_submenusub.file);
        }
    }

    function handleName(value){
        setName(value); 
        props.RegisterData('pdf', { "id" : props.id, "name" : value, "file" : file, "index" : props.index }, props.index);
    }

    function handleFile(value){
        setStateFile({ "id" : props.id });
        setFile(value); 
        props.RegisterData('pdf', { "id" : props.id, "name" : name, "file" : value, "index" : props.index }, props.index);
    }

    return(
        <div className="Pdf">
            <div className="div_input">
                <div>
                    Nome
                </div>
                <div className="space_input">
                    <input type="text" maxLength="60" value={ name } onChange={ (e)=>{ handleName(e.target.value) } } />
                </div>                
                <label> 
                    <div className={ stateFile.id == props.id ? "pdf_search add_file" : "pdf_search" }>
                        <div className="input_file"> 
                            {
                               stateFile.id == props.id ? "Arquivo carregada" : "Adicionar arquivo"
                            }
                        </div>                       
                        <input type="file" onChange={ (e)=>{ handleFile(e.target.files[0]) } } accept="application/pdf"/>
                    </div>
                </label>
                {
                    file !="" ? 
                    <div onClick={ ()=>{ SetModalData("ShowFile", { "type" : "ShowFile", "file" : file }); SetModalState("ShowFile", true); } }>
                        <SvgPdf color="#F00000" className="svg_opt" />
                    </div> : null  
                }
            </div>
        </div>
    )
}