
import { useRef, useState, useEffect } from "react";

import './Video.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function Video(props){

    // const selected = useRef();
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ typeLink, setTypeLink ]       = useState('');
    const [ link, setLink ]               = useState('');
    const [ titleVideo, setTitleVideo ]   = useState('');

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);

        RegisterCustomization('menu', setListMenu);

        // selected.current.value = typeLink;
    }, []); 

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialTypeLink();
            initialLink();
            initialTitleVideo();
        }
    }, [pageState]);    

    function initialTypeLink(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setTypeLink(data_menu.typeLink);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setTypeLink(data_submenu.typeLink);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setTypeLink(data_submenusub.typeLink);
        }
    }
    function initialLink(){  
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setLink(data_menu.link);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setLink(data_submenu.link);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setLink(data_submenusub.link);
        }   
    }
    function initialTitleVideo(){  
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setTitleVideo(data_menu.title);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setTitleVideo(data_submenu.title);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setTitleVideo(data_submenusub.title);
        }   
    }

    function handleTypeLink(value){
        setTypeLink(value); 
        props.RegisterData('video', { "id" : props.id, "typeLink" : value, "title" : titleVideo, "link" : link, "index" : props.index }, props.index);
    }

    function handleLink(value){
        setLink(value); 
        props.RegisterData('video', { "id" : props.id, "typeLink" : typeLink, "title" : titleVideo, "link" : value, "index" : props.index }, props.index);
    }

    function handleTitleVideo(value){
        setTitleVideo(value); 
        props.RegisterData('video', { "id" : props.id, "typeLink" : typeLink, "title" : value, "link" : link, "index" : props.index }, props.index);
    }

    return(
        <div className="Videos">
            <div className="div_input">
                <div>
                    Tipo
                </div>
                <div>
                    <select onChange={ (e)=>{ handleTypeLink(e.target.value) } } value={ typeLink } defaultValue={ typeLink }>
                        <option value="">#</option>
                        {/* <option value="Web">Web</option> */}
                        <option value="Vimeo">Vimeo</option>
                        <option value="Youtube">Youtube</option>
                    </select>
                </div>
                <div>
                    Título
                </div>
                <div className="space_input">
                    <input type="text" onChange={ (e)=>{ handleTitleVideo(e.target.value) } } value={ titleVideo } />
                </div>
                <div>
                    Link
                </div>
                <div>
                    <input type="text" onChange={ (e)=>{ handleLink(e.target.value) } } value={ link } />
                </div>
            </div>
        </div>
    )
}