
import { useState, useEffect } from "react";

import './Text.css';

import TextareaAutosize from 'react-textarea-autosize';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function Text(props){

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ text, setText ] = useState('');

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);

        RegisterCustomization('menu', setListMenu);
    }, []);    

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialText();
        }
    }, [pageState]);

    function initialText(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);
        
        switch(props.type){
            case "menu":
                const data_menu  = dataMenu.contents.find(item => item.id == props.id);
                setText(data_menu.text.replace('<br />', '\n'));
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setText(data_submenu.text.replace('<br />', '\n'));
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setText(data_submenusub.text.replace('<br />', '\n'));
        }
    }     

    function handleText(value){
        setText(value); 
        props.RegisterData('text', { "id" : props.id, "text" : value, "index" : props.index }, props.index);
    }

    return(
        <div className="Text">
            <div className="div_input">  
                <div>
                    Texto
                </div>
                <TextareaAutosize className="class_text" value={ text } onChange={ (e)=>{ handleText(e.target.value) } } />
            </div>
        </div>
    )
}