
import { SetListPag }  from 'interface/InterfacePag.js';
import { SetUserData } from 'interface/InterfaceUsers.js';

import Axios from 'axios';
import Cookies from 'universal-cookie';

export default function Access(reg_email, reg_pass, callback_success, callback_error){    
    const data    = new FormData();
    data.append('email', reg_email);
    data.append('password', reg_pass);

    Axios({
        url     : '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        if(response.data.access == 1){ 
            const cookies = new Cookies(); 
            let data_save = reg_email + ';' + reg_pass;
            let data_page = 'dashmetazonia.chroma-garden.com';
            cookies.set('metazonia', data_save, '/', data_page);
            
            if(cookies.get('metazonia_register')){  
                let cookiesPages_ = cookies.get('metazonia_register'); 
                SetListPag('currentRegister', cookiesPages_); 
            }else {
                cookies.set('metazonia_register', {"idMenu" : 0, "idSubMenu" : 0, "idSubMenu_sub" : 0}, '/', data_page);
                SetListPag('currentRegister', {"idMenu" : 0, "idSubMenu" : 0, "idSubMenu_sub" : 0}); 
            }

            callback_success();
            
            SetListPag('page', 'index');
            SetUserData('nome', response.data.nome);
            SetUserData('email', response.data.email);

        }else {
            callback_error();
        }
    })
}