import './Contents.css';

import FixedTopic from "components/FixedTopic";
import MenuLeft from 'components/MenuLeft';
import ContentList from 'components/ContentList';

export default function Contents(){
    return(
        <div className="Contents">
            <FixedTopic />
            <div className="list_data">
                <MenuLeft />
                <ContentList />
            </div>
        </div>
    )
}