import React, { useRef, useState, useEffect } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

import './Crop.css';

import { SetModalData, SetModalState, RegisterModalData, RegisterModalObserver } from 'interface/InterfacePopUp.js';

import { SvgClose } from 'components/SvgFile';

export default function Crop(){
    
    const DivImg = useRef();

    const [ modalData, setModaldata ]         = useState({});
    const [ showPopUp, setShowPopUp ]         = useState(false);
    const [ image, setImage ]                 = useState(null);
    const [ crop, setCrop ]                   = useState({});

    useEffect(()=>{
        RegisterModalObserver('Crop', setShowPopUp);
        RegisterModalData('Crop', setModaldata);
    }, []);

    function ClosePopUp(){
        SetModalState('Crop', false);
    }

    function save_data (){   
         modalData.func('img', { 
            "upload"    : true,
            "id"        : modalData.id, 
            "div_width"  : DivImg.current.width, 
            "div_height" : DivImg.current.height, 
            "index"     : modalData.index, 
            "img"       : modalData.img, 
            "showImg"   : modalData.showImg, 
            "positionX" : crop.x, 
            "positionY" : crop.y, 
            "width"     : crop.width, 
            "height"    : crop.height 
        }, modalData.index);
        SetModalData('Crop', { "id" : modalData.id, "upload" : true })
        SetModalState('Crop', false);
    }

    function error(){
    }
 
    // Starts cropping at 9/16 ratio centered on image
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;      
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        );      
        setCrop(crop);
    }

    return (
        (showPopUp ? 
            <div className="PopUpCrop">
                <div className="all">
                    <div className="div_data">

                        <div className="topic">
                            <div className="title">
                                Recorte da imagem
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#FFFFFF" className="icon_svg" />
                            </div>
                        </div>

                        <div className="content">
                            <div className="register_crop" onClick={ ()=>{ save_data() } }>Salvar</div>
                            <div className="image_crop">
                                <ReactCrop crop={ crop } onChange={ setCrop }>
                                    <img src={ modalData.showImg } onLoad={ onImageLoad } ref={ DivImg } className="image" />
                                </ReactCrop>
                            </div>
                        </div>
                        
                    </div>    
                </div>
            </div> 
        : <React.Fragment></React.Fragment>)
    );
}