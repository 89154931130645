
import { useState, useEffect } from "react";

import './Title.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function Title(props){

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ title, setTitle ]       = useState('');
    const [ subTitle, setSubTitle ] = useState('');

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);

        RegisterCustomization('menu', setListMenu);
    }, []);    

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialTitle();
            initialSubTitle();
        }
    }, [pageState]);  

    function initialTitle(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setTitle(data_menu.title);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setTitle(data_submenu.title);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setTitle(data_submenusub.title);
        }
    }
    function initialSubTitle(){
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setSubTitle(data_menu.subtitle);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setSubTitle(data_submenu.subtitle);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setSubTitle(data_submenusub.subtitle);
        }
    }

    function handleTitle(value){
        setTitle(value); 
        props.RegisterData('title', { "id" : props.id, "title" : value, "subtitle" : subTitle, "index" : props.index }, props.index);
    }

    function handleSubTitle(value){
        setSubTitle(value); 
        props.RegisterData('title', { "id" : props.id, "title" : title, "subtitle" : value, "index" : props.index }, props.index);
    }

    return(
        <div className="Title">
            <div className="div_input">
                <div>
                    Título
                </div>
                <div className="space_input">
                    <input type="text" maxLength="140" value={ title } onChange={ (e)=>{ handleTitle(e.target.value); } } />
                </div>
            </div>
            <div className="div_input" style={ { marginBottom: 0 } }>
                <div>
                    Subtítulo
                </div>
                <div className="space_input">
                    <input type="text" maxLength="140" value={ subTitle } onChange={ (e)=>{ handleSubTitle(e.target.value) } } />
                </div>
            </div>
        </div>
    )
}